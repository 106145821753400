/* eslint-disable import/no-anonymous-default-export */
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import Config from "./config";
import { onError } from "@apollo/client/link/error";
import "cross-fetch/polyfill";

const API_URL = Config.BACKEND_API_URL + "/graphql";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

// header Authorization
const authLink = new ApolloLink((operation, forward) => {
  const authToken = localStorage["auth-token"];
  if (authToken) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  }
  return forward(operation);
});

export default class {
  static doQuery(query) {
    const client = new ApolloClient({
      link: ApolloLink.from([
        authLink,
        errorLink,
        new HttpLink({ uri: API_URL }),
      ]),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
    return client
      .query(query)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.graphQLErrors) {
          return error.graphQLErrors;
        }
        return "HTTP_ERROR";
      });
  }

  static doMutate(mutation) {
    const client = new ApolloClient({
      link: ApolloLink.from([
        authLink,
        errorLink,
        new HttpLink({ uri: API_URL }),
      ]),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
    return client
      .mutate(mutation)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (JSON.stringify(error).indexOf("Invalid nonce") > -1) {
          return error.graphQLErrors[0].message;
        }
        if (error.graphQLErrors) {
          return error.graphQLErrors;
        }
        return "NETWORK_ERROR";
      });
  }
}
